import 'jspdf-autotable'
import Loading from "@/components/widgets/Loading.vue"

export default {
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  components: {
    Loading
  },
  data() {
    return {
      loading: false,
      company: {
        company_name: '',
        address: '',
        tax_number: '',
        phone_number: '',
      },
      document: {
        document_no: '',
        created_at: '',
        paid_at: '',
        quotation_no: '',
        creator: '',
      },
      customer: {
        code: '',
        name: '',
        address: '',
        tax_id: '',
        phone_number: ''
      },
      items: [],
      deposit: 0
    }
  },
  watch: {
    'data'(newValue, oldValue) {
      if (newValue) {
        const company = newValue.branch_detail

        if (company) {
          this.company.company_name = company.company_name
          this.company.address = company.address
          this.company.tax_number = company.tax_id
          this.company.phone_number = company.phone_number
        }        

        this.document.document_no = newValue.document_no
        this.document.created_at = newValue.created_at
        this.document.paid_at = newValue.paid_at
        this.document.quotation_no = newValue.quotation.quotation_no
        this.document.creator = newValue.creator_detail ? newValue.creator_detail?.name : '-'

        const bill = newValue.quotation
        if (bill) {
          this.customer.code = bill.customer_detail.code
          this.customer.name = bill.customer_detail.name
          this.customer.company_name = bill.customer_detail.company_name
          this.customer.address = bill.customer_detail.address
          this.customer.tax_id = bill.customer_detail.tax_id
          this.customer.phone_number = bill.customer_detail.phone_number
        }

        this.items.push(newValue.quotation)
        this.deposit = newValue.amount
      }
    }
  },
  methods: {
    async onInitIFrame() {
      this.loading = true
      const result = await this.$axios.get(
        this.$host + "/api/deposit-pdf/"+ this.$route.params?.id,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          responseType: "blob"
        }
      )
      const blob = new Blob([result.data], {type: 'application/pdf'});
      const objectUrl = URL.createObjectURL(blob)

      const element = document.getElementById('deposit-sheet')
      element.src = objectUrl

      setTimeout(() => {
        this.loading = false
        this.onIframePrint()
      }, 1000);
    },
    onIframePrint() {
      let iFrame = document.getElementById("deposit-sheet")
      iFrame.focus()
      iFrame.contentWindow.print()
    }
  }
}